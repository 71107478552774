@use "@material/layout-grid/mdc-layout-grid";

@import "../css/colors.scss";
@import "../css/defaults.scss";
@import "../css/font.scss";

*,
*:before,
*:after {
  box-sizing: border-box;
}

address,
article,
aside,
audio,
blockquote,
canvas,
dd,
dl,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
noscript,
output,
ol,
p,
pre,
section,
table,
tfoot,
ul,
video {
  margin: 20px 0;
}

html {
  font-size: 100%;

  margin: 0;
  padding: 0;
}

body {
  font-size: 16px;
  min-height: 100%;
  background-color: white;
  font-family:
    Roboto,
    Helvetica Neue,
    sans-serif;
  margin: 0;
  padding: 0;
}

section {
  margin: 15px;
}

h2 {
  font-size: 19px;

  &.no-margin-bottom {
    margin-bottom: 0;
  }
}

h4 {
  font-weight: normal;
}

#page {
  max-width: 1042px;
}

#page-container {
  padding: 10px;
  padding-top: 0;
  background-color: white;
}

.section-append {
  background-color: #3a5d8c;
  margin-top: -15px;

  &.border {
    border-top: 1px solid $blockBorderColor;
  }
}

#page {
  margin: 0 auto;
}

h1 {
  font-size: 5rem;
}

.action-area {
  background: $blockBackground;

  border-top: 1px solid transparent;
  margin-top: 0;
  min-height: 323px;
  border-top-left-radius: 15px 15px;
  border-top-right-radius: 15px 15px;
}

.radio-row {
  margin-top: 25px;
  > * {
    > * {
      margin-bottom: 15px;
      display: block;
    }
  }
}

.marginTop25 {
  margin-top: 25px;
}

.marginTop15 {
  margin-top: 15px;
}

.padding25 {
  padding: 25px;
}

.unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: #3a5d8c;
}

a.topic-link {
  text-decoration: none;
}
