:root {
  --mdc-layout-grid-margin-desktop: 24px;
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-column-width-desktop: 72px;
  --mdc-layout-grid-margin-tablet: 16px;
  --mdc-layout-grid-gutter-tablet: 16px;
  --mdc-layout-grid-column-width-tablet: 72px;
  --mdc-layout-grid-margin-phone: 16px;
  --mdc-layout-grid-gutter-phone: 16px;
  --mdc-layout-grid-column-width-phone: 72px;
}

@media (width >= 840px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 24px);
    margin: 0 auto;
  }
}

@media (width >= 600px) and (width <= 839px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-tablet, 16px);
    margin: 0 auto;
  }
}

@media (width <= 599px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-phone, 16px);
    margin: 0 auto;
  }
}

@media (width >= 840px) {
  .mdc-layout-grid__inner {
    margin: -12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2 * -1);
    flex-flow: wrap;
    align-items: stretch;
    display: flex;
  }

  @supports (display: grid) {
    .mdc-layout-grid__inner {
      grid-gap: 24px;
      grid-gap: var(--mdc-layout-grid-gutter-desktop, 24px);
      grid-template-columns: repeat(12, minmax(0, 1fr));
      margin: 0;
      display: grid;
    }
  }
}

@media (width >= 600px) and (width <= 839px) {
  .mdc-layout-grid__inner {
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2 * -1);
    flex-flow: wrap;
    align-items: stretch;
    display: flex;
  }

  @supports (display: grid) {
    .mdc-layout-grid__inner {
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-tablet, 16px);
      grid-template-columns: repeat(8, minmax(0, 1fr));
      margin: 0;
      display: grid;
    }
  }
}

@media (width <= 599px) {
  .mdc-layout-grid__inner {
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2 * -1);
    flex-flow: wrap;
    align-items: stretch;
    display: flex;
  }

  @supports (display: grid) {
    .mdc-layout-grid__inner {
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-phone, 16px);
      grid-template-columns: repeat(4, minmax(0, 1fr));
      margin: 0;
      display: grid;
    }
  }
}

@media (width >= 840px) {
  .mdc-layout-grid__cell {
    width: calc(33.3333% - 24px);
    width: calc(33.3333% - var(--mdc-layout-grid-gutter-desktop, 24px));
    box-sizing: border-box;
    margin: 12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2);
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell {
      grid-column-end: span 4;
      width: auto;
      margin: 0;
    }
  }

  .mdc-layout-grid__cell--span-1, .mdc-layout-grid__cell--span-1-desktop {
    width: calc(8.33333% - 24px);
    width: calc(8.33333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1, .mdc-layout-grid__cell--span-1-desktop {
      grid-column-end: span 1;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-2, .mdc-layout-grid__cell--span-2-desktop {
    width: calc(16.6667% - 24px);
    width: calc(16.6667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2, .mdc-layout-grid__cell--span-2-desktop {
      grid-column-end: span 2;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-3, .mdc-layout-grid__cell--span-3-desktop {
    width: calc(25% - 24px);
    width: calc(25% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3, .mdc-layout-grid__cell--span-3-desktop {
      grid-column-end: span 3;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-4, .mdc-layout-grid__cell--span-4-desktop {
    width: calc(33.3333% - 24px);
    width: calc(33.3333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4, .mdc-layout-grid__cell--span-4-desktop {
      grid-column-end: span 4;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-5, .mdc-layout-grid__cell--span-5-desktop {
    width: calc(41.6667% - 24px);
    width: calc(41.6667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5, .mdc-layout-grid__cell--span-5-desktop {
      grid-column-end: span 5;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-6, .mdc-layout-grid__cell--span-6-desktop {
    width: calc(50% - 24px);
    width: calc(50% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6, .mdc-layout-grid__cell--span-6-desktop {
      grid-column-end: span 6;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-7, .mdc-layout-grid__cell--span-7-desktop {
    width: calc(58.3333% - 24px);
    width: calc(58.3333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7, .mdc-layout-grid__cell--span-7-desktop {
      grid-column-end: span 7;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-8, .mdc-layout-grid__cell--span-8-desktop {
    width: calc(66.6667% - 24px);
    width: calc(66.6667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8, .mdc-layout-grid__cell--span-8-desktop {
      grid-column-end: span 8;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-9, .mdc-layout-grid__cell--span-9-desktop {
    width: calc(75% - 24px);
    width: calc(75% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9, .mdc-layout-grid__cell--span-9-desktop {
      grid-column-end: span 9;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-10, .mdc-layout-grid__cell--span-10-desktop {
    width: calc(83.3333% - 24px);
    width: calc(83.3333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10, .mdc-layout-grid__cell--span-10-desktop {
      grid-column-end: span 10;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-11, .mdc-layout-grid__cell--span-11-desktop {
    width: calc(91.6667% - 24px);
    width: calc(91.6667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11, .mdc-layout-grid__cell--span-11-desktop {
      grid-column-end: span 11;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-12, .mdc-layout-grid__cell--span-12-desktop {
    width: calc(100% - 24px);
    width: calc(100% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12, .mdc-layout-grid__cell--span-12-desktop {
      grid-column-end: span 12;
      width: auto;
    }
  }
}

@media (width >= 600px) and (width <= 839px) {
  .mdc-layout-grid__cell {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2);
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell {
      grid-column-end: span 4;
      width: auto;
      margin: 0;
    }
  }

  .mdc-layout-grid__cell--span-1, .mdc-layout-grid__cell--span-1-tablet {
    width: calc(12.5% - 16px);
    width: calc(12.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1, .mdc-layout-grid__cell--span-1-tablet {
      grid-column-end: span 1;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-2, .mdc-layout-grid__cell--span-2-tablet {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2, .mdc-layout-grid__cell--span-2-tablet {
      grid-column-end: span 2;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-3, .mdc-layout-grid__cell--span-3-tablet {
    width: calc(37.5% - 16px);
    width: calc(37.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3, .mdc-layout-grid__cell--span-3-tablet {
      grid-column-end: span 3;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-4, .mdc-layout-grid__cell--span-4-tablet {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4, .mdc-layout-grid__cell--span-4-tablet {
      grid-column-end: span 4;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-5, .mdc-layout-grid__cell--span-5-tablet {
    width: calc(62.5% - 16px);
    width: calc(62.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5, .mdc-layout-grid__cell--span-5-tablet {
      grid-column-end: span 5;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-6, .mdc-layout-grid__cell--span-6-tablet {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6, .mdc-layout-grid__cell--span-6-tablet {
      grid-column-end: span 6;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-7, .mdc-layout-grid__cell--span-7-tablet {
    width: calc(87.5% - 16px);
    width: calc(87.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7, .mdc-layout-grid__cell--span-7-tablet {
      grid-column-end: span 7;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-8, .mdc-layout-grid__cell--span-8-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8, .mdc-layout-grid__cell--span-8-tablet {
      grid-column-end: span 8;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-9, .mdc-layout-grid__cell--span-9-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9, .mdc-layout-grid__cell--span-9-tablet {
      grid-column-end: span 8;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-10, .mdc-layout-grid__cell--span-10-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10, .mdc-layout-grid__cell--span-10-tablet {
      grid-column-end: span 8;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-11, .mdc-layout-grid__cell--span-11-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11, .mdc-layout-grid__cell--span-11-tablet {
      grid-column-end: span 8;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-12, .mdc-layout-grid__cell--span-12-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12, .mdc-layout-grid__cell--span-12-tablet {
      grid-column-end: span 8;
      width: auto;
    }
  }
}

@media (width <= 599px) {
  .mdc-layout-grid__cell {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2);
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell {
      grid-column-end: span 4;
      width: auto;
      margin: 0;
    }
  }

  .mdc-layout-grid__cell--span-1, .mdc-layout-grid__cell--span-1-phone {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-phone, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1, .mdc-layout-grid__cell--span-1-phone {
      grid-column-end: span 1;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-2, .mdc-layout-grid__cell--span-2-phone {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-phone, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2, .mdc-layout-grid__cell--span-2-phone {
      grid-column-end: span 2;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-3, .mdc-layout-grid__cell--span-3-phone {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-phone, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3, .mdc-layout-grid__cell--span-3-phone {
      grid-column-end: span 3;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-4, .mdc-layout-grid__cell--span-4-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4, .mdc-layout-grid__cell--span-4-phone {
      grid-column-end: span 4;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-5, .mdc-layout-grid__cell--span-5-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5, .mdc-layout-grid__cell--span-5-phone {
      grid-column-end: span 4;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-6, .mdc-layout-grid__cell--span-6-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6, .mdc-layout-grid__cell--span-6-phone {
      grid-column-end: span 4;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-7, .mdc-layout-grid__cell--span-7-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7, .mdc-layout-grid__cell--span-7-phone {
      grid-column-end: span 4;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-8, .mdc-layout-grid__cell--span-8-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8, .mdc-layout-grid__cell--span-8-phone {
      grid-column-end: span 4;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-9, .mdc-layout-grid__cell--span-9-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9, .mdc-layout-grid__cell--span-9-phone {
      grid-column-end: span 4;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-10, .mdc-layout-grid__cell--span-10-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10, .mdc-layout-grid__cell--span-10-phone {
      grid-column-end: span 4;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-11, .mdc-layout-grid__cell--span-11-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11, .mdc-layout-grid__cell--span-11-phone {
      grid-column-end: span 4;
      width: auto;
    }
  }

  .mdc-layout-grid__cell--span-12, .mdc-layout-grid__cell--span-12-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }

  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12, .mdc-layout-grid__cell--span-12-phone {
      grid-column-end: span 4;
      width: auto;
    }
  }
}

.mdc-layout-grid__cell--order-1 {
  order: 1;
}

.mdc-layout-grid__cell--order-2 {
  order: 2;
}

.mdc-layout-grid__cell--order-3 {
  order: 3;
}

.mdc-layout-grid__cell--order-4 {
  order: 4;
}

.mdc-layout-grid__cell--order-5 {
  order: 5;
}

.mdc-layout-grid__cell--order-6 {
  order: 6;
}

.mdc-layout-grid__cell--order-7 {
  order: 7;
}

.mdc-layout-grid__cell--order-8 {
  order: 8;
}

.mdc-layout-grid__cell--order-9 {
  order: 9;
}

.mdc-layout-grid__cell--order-10 {
  order: 10;
}

.mdc-layout-grid__cell--order-11 {
  order: 11;
}

.mdc-layout-grid__cell--order-12 {
  order: 12;
}

.mdc-layout-grid__cell--align-top {
  align-self: flex-start;
}

@supports (display: grid) {
  .mdc-layout-grid__cell--align-top {
    align-self: start;
  }
}

.mdc-layout-grid__cell--align-middle {
  align-self: center;
}

.mdc-layout-grid__cell--align-bottom {
  align-self: flex-end;
}

@supports (display: grid) {
  .mdc-layout-grid__cell--align-bottom {
    align-self: end;
  }
}

@media (width >= 840px) {
  .mdc-layout-grid--fixed-column-width {
    width: 1176px;
    width: calc(var(--mdc-layout-grid-column-width-desktop, 72px) * 12 + var(--mdc-layout-grid-gutter-desktop, 24px) * 11 + var(--mdc-layout-grid-margin-desktop, 24px) * 2);
  }
}

@media (width >= 600px) and (width <= 839px) {
  .mdc-layout-grid--fixed-column-width {
    width: 720px;
    width: calc(var(--mdc-layout-grid-column-width-tablet, 72px) * 8 + var(--mdc-layout-grid-gutter-tablet, 16px) * 7 + var(--mdc-layout-grid-margin-tablet, 16px) * 2);
  }
}

@media (width <= 599px) {
  .mdc-layout-grid--fixed-column-width {
    width: 368px;
    width: calc(var(--mdc-layout-grid-column-width-phone, 72px) * 4 + var(--mdc-layout-grid-gutter-phone, 16px) * 3 + var(--mdc-layout-grid-margin-phone, 16px) * 2);
  }
}

.mdc-layout-grid--align-left {
  margin-left: 0;
  margin-right: auto;
}

.mdc-layout-grid--align-right {
  margin-left: auto;
  margin-right: 0;
}

.body-text {
  font-size: 90%;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Regular.ca197847.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Medium.91322885.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Bold.fdb9b54a.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

*, :before, :after {
  box-sizing: border-box;
}

address, article, aside, audio, blockquote, canvas, dd, dl, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, noscript, output, ol, p, pre, section, table, tfoot, ul, video {
  margin: 20px 0;
}

html {
  margin: 0;
  padding: 0;
  font-size: 100%;
}

body {
  background-color: #fff;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 16px;
}

section {
  margin: 15px;
}

h2 {
  font-size: 19px;
}

h2.no-margin-bottom {
  margin-bottom: 0;
}

h4 {
  font-weight: normal;
}

#page {
  max-width: 1042px;
}

#page-container {
  background-color: #fff;
  padding: 0 10px 10px;
}

.section-append {
  background-color: #3a5d8c;
  margin-top: -15px;
}

.section-append.border {
  border-top: 1px solid #d3d4d4;
}

#page {
  margin: 0 auto;
}

h1 {
  font-size: 5rem;
}

.action-area {
  background: #d9e1ec;
  border-top: 1px solid #0000;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  min-height: 323px;
  margin-top: 0;
}

.radio-row {
  margin-top: 25px;
}

.radio-row > * > * {
  margin-bottom: 15px;
  display: block;
}

.marginTop25 {
  margin-top: 25px;
}

.marginTop15 {
  margin-top: 15px;
}

.padding25 {
  padding: 25px;
}

.unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: #3a5d8c;
}

a.topic-link {
  text-decoration: none;
}
/*# sourceMappingURL=index.b2538cc2.css.map */
